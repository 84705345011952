import * as Sentry from "@sentry/react";

export async function handleResponse(response) {
  if (response.ok) {
    return response.json();
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  } else if (response.status === 401) {
    //TODO Get token and retry.
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  if (process.env.REACT_APP_ENV_LOG === "true") Sentry.captureException(error);
  console.error("API call failed. " + error);
  throw error;
}

export async function handleFileResponse(response) {
  if (response.ok) return response.blob();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  } else if (response.status === 401) {
    //TODO Get token and retry.
  }
  throw new Error("Network response was not ok.");
}

export async function handleFileResponseWithRecordId(response) {
  if (response.ok) {
    const recordId = response.headers.get("x-record-id");
    const blob = await response.blob();
    return {
      recordId: recordId,
      blob: blob,
    };
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  } else if (response.status === 401) {
    //TODO Get token and retry.
  }
  throw new Error("Network response was not ok.");
}
